var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-section-content"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:({
      path: _vm.size > 0 ? 'dashboard.widget-title.school-list' : 'dashboard.widget-title.school-list-2',
      args: { name: _vm.name },
    }),expression:"{\n      path: size > 0 ? 'dashboard.widget-title.school-list' : 'dashboard.widget-title.school-list-2',\n      args: { name },\n    }"}],staticClass:"fav-list__title"}),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
      path: _vm.size > 0 ? 'dashboard.favorites.description' : 'dashboard.favorites.description2',
      args: { size: _vm.size },
    }),expression:"{\n      path: size > 0 ? 'dashboard.favorites.description' : 'dashboard.favorites.description2',\n      args: { size },\n    }"}],staticClass:"fav-list__description"}),_c('div',{staticClass:"fav-list__length"},[_vm._v(" "+_vm._s(_vm.size)+" ")]),(_vm.loading || !_vm.favoritesFilled)?_c('div',{staticClass:"full-width d-flex justify-center pt-24"},[_c('div',{staticClass:"LoaderBalls"},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"LoaderBalls__item"})}),0)]):_c('div',_vm._l((_vm.favorites),function(school,index){return _c('div',{key:index,staticClass:"school-back"},[_c('div',{staticClass:"fav-list"},[_c('v-avatar',{attrs:{"size":"50"}},[_c('v-img',{staticClass:"image-dash",attrs:{"src":school.image_thumb?.length > 0
                ? school.image_thumb[0].image_link
                : require('@/assets/dashboard/widgets/school-no-available-widget.svg')}})],1),_c('div',[_c('h3',{staticClass:"fav-table--name-title"},[_vm._v(" "+_vm._s(school.campus_name)+" ")]),_c('div',{staticClass:"flex ml-2.5"},[_c('img',{attrs:{"src":require("@/assets/dashboard/widgets/walking-distance-widget.svg"),"alt":"walking"}}),_c('p',{staticClass:"fav-table--name-distance"},[_vm._v(" "+_vm._s(school.walkData)+" ")])])]),_c('div',{staticClass:"fav-table--arrow"},[_c('v-icon',{attrs:{"x-medium":""},on:{"click":function($event){return _vm.exploreCampusDetail(school)},"keydown":function($event){return _vm.exploreCampusDetail(school)}}},[_vm._v(" mdi-chevron-right ")])],1)],1)])}),0),(!_vm.loading && _vm.favorites.length < 1 && _vm.favoritesFilled)?_c('div',_vm._l((_vm.noList),function(item,index){return _c('div',{key:index,staticClass:"school-back"},[_c('div',{staticClass:"fav-list"},[_c('v-avatar',{attrs:{"size":"50"}},[_c('v-img',{staticClass:"image-dash",attrs:{"src":require("@/assets/dashboard/widgets/school-no-available-widget.svg")}})],1),_c('div',[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('dashboard.favorites.school-no-available'),expression:"'dashboard.favorites.school-no-available'"}],staticClass:"fav-table--name-title"}),_vm._m(0,true)]),_c('div',{staticClass:"fav-table--arrow"},[_c('v-icon',{attrs:{"x-medium":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)])}),0):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"800px","content-class":"favorites__dialog"},model:{value:(_vm.campusDetailModal),callback:function ($$v) {_vm.campusDetailModal=$$v},expression:"campusDetailModal"}},[(_vm.campusDetailModal)?_c('CampusDetail',{staticStyle:{"z-index":"1000"},attrs:{"in-dashboard":""},on:{"close":_vm.closeCampusDetail}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex ml-2.5"},[_c('img',{attrs:{"src":require("@/assets/dashboard/widgets/pin-location-widget.svg"),"alt":"walking"}}),_c('p',{staticClass:"fav-table--name-distance"},[_vm._v(" - ")])])
}]

export { render, staticRenderFns }