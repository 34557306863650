<template>
  <div class="dashboard-section-content">
    <h3
      v-t="{
        path: size > 0 ? 'dashboard.widget-title.school-list' : 'dashboard.widget-title.school-list-2',
        args: { name },
      }"
      class="fav-list__title"
    />
    <span
      v-t="{
        path: size > 0 ? 'dashboard.favorites.description' : 'dashboard.favorites.description2',
        args: { size },
      }"
      class="fav-list__description"
    />
    <div class="fav-list__length">
      {{ size }}
    </div>
    <div v-if="loading || !favoritesFilled" class="full-width d-flex justify-center pt-24">
      <div class="LoaderBalls">
        <div v-for="index in 3" :key="index" class="LoaderBalls__item" />
      </div>
    </div>
    <div v-else>
      <!-- each school -->
      <div
        v-for="(school, index) in favorites"
        :key="index"
        class="school-back"
      >
        <div class="fav-list">
          <v-avatar size="50">
            <v-img
              class="image-dash"
              :src="
                school.image_thumb?.length > 0
                  ? school.image_thumb[0].image_link
                  : require('@/assets/dashboard/widgets/school-no-available-widget.svg')
              "
            />
          </v-avatar>
          <div>
            <h3 class="fav-table--name-title">
              {{ school.campus_name }}
            </h3>
            <div class="flex ml-2.5">
              <img
                src="@/assets/dashboard/widgets/walking-distance-widget.svg"
                alt="walking"
              />
              <p class="fav-table--name-distance">
                {{ school.walkData }}
              </p>
            </div>
          </div>
          <div class="fav-table--arrow">
            <v-icon
              x-medium
              @click="exploreCampusDetail(school)"
              @keydown="exploreCampusDetail(school)"
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && favorites.length < 1 && favoritesFilled">
      <div
        v-for="(item, index) in noList"
        :key="index"
        class="school-back"
      >
        <div class="fav-list">
          <v-avatar size="50">
            <v-img
              class="image-dash"
              src="@/assets/dashboard/widgets/school-no-available-widget.svg"
            />
          </v-avatar>
          <div>
            <h3 v-t="'dashboard.favorites.school-no-available'" class="fav-table--name-title" />
            <div class="flex ml-2.5">
              <img
                src="@/assets/dashboard/widgets/pin-location-widget.svg"
                alt="walking"
              />
              <p class="fav-table--name-distance">
                -
              </p>
            </div>
          </div>
          <div class="fav-table--arrow">
            <v-icon
              x-medium
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- modal campus detail / digital profile -->
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <CampusDetail
        v-if="campusDetailModal"
        style="z-index: 1000"
        in-dashboard
        @close="closeCampusDetail"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import utils from '@/utils';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'SchoolList',
  metaInfo: {
    meta: [],
  },
  components: {
    CampusDetail,
  },
  data() {
    return {
      campusDetailModal: false,
      size: '0',
      favorites: [],
      favoritesFilled: false,
      noList: 3,
    };
  },
  computed: {
    ...mapGetters({
      favoriteSchoolsDetails: 'favorites/listFavoriteSchools',
      loading: 'favorites/loadingSchoolsDetails',
      homeLocation: 'authentication/homeLocation',
      legalGuardian: 'authentication/legalGuardian',
    }),
    name() {
      return this.legalGuardian ? this.legalGuardian.firstName : '';
    },
  },
  watch: {
    favoriteSchoolsDetails() {
      this.getFavoriteSchools();
    },
  },
  async mounted() {
    const getDetails = utils.isGetDetailsFavoritesSchools(this.favoriteSchoolsDetails);
    this.getFavoriteSchools();
    if (getDetails && this.favoriteSchoolsDetails.length > 0) {
      await this.retrieveListFavorites({ isInLogin: false, getDetails: true });
    }
  },
  async created() {
    const getDetails = utils.isGetDetailsFavoritesSchools(this.favoriteSchoolsDetails);

    if (getDetails && this.favoriteSchoolsDetails.length > 0) {
      await this.retrieveListFavorites({ isInLogin: false, getDetails: true });
    } else {
      this.setLoadingSchoolsDetails(false);
    }
  },
  methods: {
    ...mapActions({
      setLoadingSchoolsDetails: 'favorites/setLoadingSchoolsDetails',
      retrieveListFavorites: 'favorites/retrieveListFavorites',
      retrieveCampusDetails: 'institutions/retrieveCampusDetails',
      getSchoolTravelTime: 'institutions/getSchoolTravelTime',

    }),
    exploreCampusDetail(school) {
      trackMixPanel('click_school_list_show_digital_profile');
      this.retrieveCampusDetails({ searchCode: school.uuid });
      this.campusDetailModal = true;
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
    async getFavoriteSchools() {
      const newFavorites = [];
      if (this.favoriteSchoolsDetails.length > 0) {
        // TODO: REFACTOR THIS - Schools come in different formats for some reason - we need to normalize.
        // Also, delete the for loop, let's use VUE v-for loops for more readability and less bugs.

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const index in this.favoriteSchoolsDetails) {
          const school = this.favoriteSchoolsDetails[index];
          let walk = this.$t('map.card.no-info');
          if (school.location && school.location.lat && school.location.lon) {
            const travelTime = {
              campusCode: school.campus_code,
              campusLat: school.location.lat,
              campusLng: school.location.lon,
              lat: this.homeLocation.lat,
              lng: this.homeLocation.lng,
            };
            // eslint-disable-next-line no-await-in-loop
            walk = await this.getSchoolTravelTime(travelTime).then((res) => {
              const walkValue = res[0].value?.data;
              return walkValue ? `${walkValue.walk_duration} • ${walkValue.walk_distance}` : walk;
            });
          }
          newFavorites.push({ ...this.favoriteSchoolsDetails[index], walkData: walk });
        }
      }
      this.favorites = newFavorites;
      this.favoritesFilled = true;
      this.size = this.favoriteSchoolsDetails.length > 0 ? this.favoriteSchoolsDetails.length : '-';
    },
  },
};
</script>
