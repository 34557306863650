<template>
  <!-- Este componente es pensado para poder ser refactorizado
  y unido con el otro componente de Profile.vue ya que tienen
  mucho código en común. Pero en organización, diseño y layout es bien diferente.
  Así que a futuro al tener el diseño final de edición headMaster se puede unir todo en un solo componente. -->
  <div class="subsection-container" :style="{ '--n-rows': 1 }">
    <Subsection
      bg-color="#F5F5F5"
      :style="{
        'border-radius': '5px',
        'background-position': 'center',
      }"
      clickable
      @onClick="showLgIdentificationModal()"
      @keydown.enter="showLgIdentificationModal()"
    >
      <div v-if="firstLetter" class="name-profile">
        <div class="name-circle">
          {{ firstLetter }}
        </div>
        <img
          class="name-profile--img"
          src="@/assets/dashboard/widgets/ellipse-widget.svg"
          alt="complete"
        />
        <div class="name-profile--name">
          {{ userUsername }}
        </div>
      </div>
      <div v-if="!firstLetter" class="name-profile d-flex flex-column align-center">
        <img
          style="width: 52px"
          src="@/assets/dashboard/widgets/no-info-person.svg"
          alt="complete"
        />
        <div v-t="'dashboard.profile.no-info-user'" class="name-profile--name name-profile--name--no-info" />
      </div>
    </Subsection>
    <Subsection
      v-if="students.length === 0"
      bg-color="#F5F5F5"
      :style="{
        'border-radius': '5px',
        'background-position': 'center',
      }"
      clickable
      @onClick="toggleProfile()"
      @keydown.enter="toggleProfile()"
    >
      <img
        class="profile-students--no-student-purple"
        src="@/assets/dashboard/widgets/add-icon-widget.svg"
        alt="tool"
      />
      <span v-t="'dashboard.profile.profile_edit.no_students'" class="profile-students--no-student" />
      <img
        class="profile-students--no-student-icon"
        style="position: absolute; bottom: 0px; right: 0px"
        src="@/assets/dashboard/widgets/add-students-widget.svg"
        alt="tool"
      />
    </Subsection>
    <div v-if="students.length > 0" class="profile-students">
      <StudentChip
        v-for="(student, index) in studentsToShow"
        :key="index"
        :student-name="student.first_name"
        :student-grade="student.grade"
        clickable
        @click="editStudent(student)"
        @keydown.enter="editStudent(student)"
      />
      <div v-if="students.length > numberOfStudentsToShow" class="flex">
        <StudentChip
          :key="students.length"
          clickable
          :student-name="`+ ${students.length - numberOfStudentsToShow}`"
        />
        <img
          class="profile-students--add"
          src="@/assets/dashboard/icons/add-student.svg"
          alt="add-student"
          @click="toggleProfile()"
          @keydown.enter="toggleProfile()"
        />
      </div>
      <div v-if="students.length < 3" class="profile-students--single-add">
        <img
          class="profile-students--add clickable"
          src="@/assets/dashboard/icons/add-student.svg"
          alt="add-student"
          @click="toggleProfile()"
          @keydown.enter="toggleProfile()"
        />
      </div>
    </div>
    <v-dialog
      v-model="showProfile"
      max-width="480px"
      persistent
      class="profile-students--dialog"
      @click:outside="closeProfile()"
      @keydown.esc="closeProfile()"
    >
      <v-card class="profile-students--add-student" :color="'#F5F5F5'">
        <v-card-title>
          <v-btn
            class="profile-students--close-btn"
            icon
            @click="closeProfile()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="profile-students--title justify-center">
            {{ $t('dashboard.profile.profile_edit.no_students') }}
            <br />
            <span v-t="'dashboard.profile.profile_edit.applicants_information'" class="profile-students--subtitle" />
          </v-toolbar-title>
          <v-spacer />
        </v-card-title>
        <v-card-text class="mt-3 pl-10 pr-10">
          <EditableStudentChip
            v-for="(student, index) in students"
            :key="index"
            :student-name="student.first_name"
            editable
            @edit="editStudent(student)"
            @delete="removeStudent(student)"
          />
        </v-card-text>
        <v-btn
          tile
          color="#EEE9FF"
          @click="addStudent()"
          @keydown.enter="addStudent()"
        >
          <img
            width="20px"
            src="@/assets/dashboard/widgets/add-icon-widget.svg"
            alt="add-student"
            @click="toggleProfile()"
            @keydown.enter="toggleProfile()"
          />
          <span v-t="'dashboard.profile.profile_edit.applicants_add'" class="profile-students--add-student--title" />
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showStudentEditModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <EditStudentSelection
        @backStep="showStudentEditModal = false"
        @nextStep="setEditStudent"
      />
    </v-dialog>
    <v-dialog
      v-model="showIdentificationModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <Identification
        v-if="stepAux === 1"
        no-stepper
        in-detailed-register
        :in-student-flow="inStudentFlow"
        :is-new-student="newStudent"
        :pre-fill-data="inStudentFlow ? currentStudent : legalGuardian"
        :pre-fill="newStudent ? false : true"
        @backStep="backStep('identification')"
        @nextStep="setIdentificationStep()"
      />
      <Nationality
        v-if="stepAux === 2"
        :in-student-flow="inStudentFlow"
        :pre-fill-data="inStudentFlow ? currentStudent : legalGuardian"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 1"
        @nextStep="inStudentFlow ? (stepAux = 4) : (stepAux = 3)"
      />
      <Contact
        v-if="stepAux === 3"
        no-stepper
        :in-student-flow="inStudentFlow"
        :pre-fill-data="contactPreferences"
        pre-fill
        @backStep="stepAux = 2"
        @nextStep="!inStudentFlow ? setNextStep() : (stepAux = 4)"
      />
      <RelationshipModal
        v-if="stepAux === 4"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="inStudentFlow ? stepAux = 2 : stepAux = 3"
        @nextStep="stepAux = 5"
      />
      <RegisterGender
        v-if="stepAux === 5"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 4"
        @nextStep="stepAux = 6"
      />
      <DateOfBirth
        v-if="stepAux === 6"
        :in-student-flow="inStudentFlow"
        :pre-fill-data="inStudentFlow ? studentToEdit : null"
        :pre-fill="newStudent ? false : true"
        @backStep="stepAux = 5"
        @nextStep="nextStep()"
      />
      <PriorityQuestion
        v-if="stepAux === 7"
        @backStep="backStep('additional')"
        @nextStep="stepAux = 8"
        @toFinale="showIdentificationModal = false"
      />
      <BrotherInCampus
        v-if="stepAux === 8"
        :pre-fill="newStudent ? false : true"
        :input-values="studentBrothers"
        @backStep="stepAux = 7"
        @nextStep="nextStep()"
      />
      <SisbenPriority
        v-if="stepAux === 10"
        :pre-fill="newStudent ? false : true"
        :input-values="studentSisben"
        @toIcbfInfo="stepAux = 12"
        @backStep="stepAux = 8"
        @nextStep="stepAux = 11"
      />
      <PreSchoolPriority
        v-if="stepAux === 11"
        :pre-fill="newStudent ? false : true"
        :input-values="studentIcbf"
        @backStep="stepAux = 10"
        @nextStep="stepAux = 13"
      />
      <IcbfInfoModal
        v-if="stepAux === 12"
        @backStep="stepAux = 10"
        @nextStep="stepAux = 13"
      />
      <ArmedConflict
        v-if="stepAux === 13"
        :pre-fill="newStudent ? false : true"
        :input-values="studentConflict"
        @backStep="stepAux = 11"
        @nextStep="stepAux = 14"
      />
      <Disability
        v-if="stepAux === 14"
        :pre-fill="newStudent ? false : true"
        :input-values="studentDisability"
        @nextStep="setNextStep()"
        @backStep="stepAux = 13"
      />
    </v-dialog>
    <v-dialog
      v-model="showEditLocation"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
      style="height: 100vh !important"
      @click:outside="showEditLocation = false"
    >
      <Location
        v-if="locationStep === 0"
        is-in-map
        @backStep="backStep('location')"
        @setCommune="setCommune"
        @setUserLocation="setUserLocation"
      />
      <div
        v-if="locationStep === 1"
        style="height: 100%"
        class="d-flex flex-column justify-space-between"
      >
        <div style="height: 100%">
          <Actions
            style="height: 12px; margin-bottom: 12px"
            @back="locationStep = 0"
          />
          <RegisterLogo inline small />
          <SmallTitle
            style="margin-top: 0px !important"
            center-left
            small-top-margin
            :text="'user_register.register_location.title'"
          />
          <TitleInstructions
            :text="'shared.location.title'"
            style="margin-bottom: 12px"
          />
          <SearchLocation
            v-if="locationStep === 1"
            is-in-map
            :commune="selectedCommune"
            :user-location="userLocation"
            :in-student-flow="inStudentFlow"
            @nextStep="nextStep"
            @setUserLocation="setUserLocation"
          />
        </div>
        <div style="padding: 20px 0 0 0">
          <MainButton
            small
            :active-value="true"
            :text="'user_register.continue'"
            @continue="setLocation()"
          />
        </div>
      </div>
      <LocationType
        v-if="locationStep === 2"
        pre-fill
        :pre-fill-data="currentUserToEdit"
        :in-student-flow="inStudentFlow"
        :register-type="registerType"
        @nextStep="locationTypeFollowUp"
        @backStep="backStep"
      />
    </v-dialog>
    <v-dialog
      v-model="showStudentGradeModal"
      :content-class="
        !mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'
      "
      max-width="450px"
    >
      <SelectGrades
        v-if="showStudentGradeModal"
        :in-student-flow="inStudentFlow"
        :student-to-set="studentToEdit"
        @sendGrade="setGrades()"
        @backStep="backStep('grade')"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import StudentChip from '@/components/atoms/chips/StudentChip.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import EditableStudentChip from '@/components/molecules/chips/EditableStudentChip.vue';
import ArmedConflict from '@/components/organisms/register/ArmedConflict.vue';
import BrotherInCampus from '@/components/organisms/register/BrotherInCampus.vue';
import Contact from '@/components/organisms/register/Contact.vue';
import DateOfBirth from '@/components/organisms/register/DateOfBirth.vue';
import Disability from '@/components/organisms/register/Disability.vue';
import EditStudentSelection from '@/components/organisms/register/EditStudentSelection.vue';
import IcbfInfoModal from '@/components/organisms/register/IcbfInfoModal.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import Location from '@/components/organisms/register/Location.vue';
import LocationType from '@/components/organisms/register/LocationType.vue';
import Nationality from '@/components/organisms/register/Nationality.vue';
import PreSchoolPriority from '@/components/organisms/register/PreSchoolPriority.vue';
import PriorityQuestion from '@/components/organisms/register/PriorityQuestion.vue';
import RegisterGender from '@/components/organisms/register/RegisterGender.vue';
import RelationshipModal from '@/components/organisms/register/RelationshipModal.vue';
import SisbenPriority from '@/components/organisms/register/SisbenPriority.vue';
import SearchLocation from '@/components/shared/SearchLocation.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';
import CONFIG from '@/config';
import { LABELS } from '@/constants/explorer/labels';
import Subsection from './Subsection.vue';

export default {
  name: 'ProfileEdit',
  components: {
    StudentChip,
    Subsection,
    PreSchoolPriority,
    SisbenPriority,
    BrotherInCampus,
    PriorityQuestion,
    RegisterGender,
    RelationshipModal,
    IcbfInfoModal,
    ArmedConflict,
    Disability,
    DateOfBirth,
    Contact,
    Nationality,
    Identification,
    EditStudentSelection,
    Location,
    Actions,
    MainButton,
    SearchLocation,
    SelectGrades,
    RegisterLogo,
    TitleInstructions,
    SmallTitle,
    LocationType,
    EditableStudentChip,
  },
  data() {
    return {
      showProfile: false,
      studentToEdit: null,
      numberOfStudentsToShow: 2,
      inStudentFlow: false,
      newStudent: false,
      showIdentificationModal: false,
      stepAux: 1,
      showEditLocation: false,
      showStudentGradeModal: false,
      showStudentEditModal: false,
      locationStep: null,
      studentBrothers: [],
      studentSisben: {},
      studentIcbf: {},
      studentConflict: {},
      studentDisability: {},
      selectedLocation: null,
      userLocation: null,
      selectedCommune: null,
      finalLat: null,
      finalLng: null,
      locationT: '',
      initialLat: null,
      initialLng: null,
      registerType: 2,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: 'authentication/legalGuardianUUID',
      campusDetail: 'roles/currentHeadMasterMainSchool',
      userUsername: 'authentication/userUsername',
      students: 'authentication/students',
      contactPreferences: 'userRegister/contactPreferences',
      profileStep: 'userRegister/profileStep',
      legalGuardian: 'authentication/legalGuardian',
      regions: 'options/regions',
      municipalities: 'options/municipalities',
      locationRegister: 'userRegister/location',
      initialCoordinates: 'userRegister/initialCoordinates',
      currentStudent: 'authentication/currentStudent',
      place: 'userRegister/place',
      buttonClicked: 'userRegister/buttonClicked',
      pinMoved: 'userRegister/pinMoved',
      locationType: 'userRegister/locationType',
    }),
    studentsToShow() {
      return this.students.slice(0, Math.min(this.numberOfStudentsToShow, this.students.length));
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    firstLetter() {
      if (this.userUsername) {
        return this.userUsername.charAt(0);
      }
      return null;
    },
    currentUserToEdit() {
      return this.legalGuardian;
    },
  },
  mounted() {
    this.checkLabels({ keysLabels: [LABELS.GENDERS, LABELS.NATIONALITIES] });
    if (this.profileStep) {
      this.showProfile = true;
    }
  },
  methods: {
    ...mapActions({
      deleteStudent: 'authentication/deleteStudent',
      success: 'utils/success',
      setCurrentStudent: 'authentication/setCurrentStudent',
      retrieveFavorites: 'favorites/retrieveListFavorites',
      retrieveExploredCampuses: 'institutions/retrieveExploredCampuses',
      retrieveExploredSchoolCard: 'institutions/retrieveExploredSchoolCard',
      retrieveCampuses: 'institutions/retrieveCampuses',
      raiseError: 'utils/error',
      getStudents: 'authentication/getStudents',
      getSchoolInstitution: 'institutions/getSchoolInstitution',
      clearSchoolInstitution: 'institutions/clearSchoolInstitution',
      setFastRegisterLocation: 'userRegister/setFastRegisterLocation',
      sendGeocodingHistory: 'geoTools/sendGeocodingHistory',
      setStudentLocation: 'newStudentRegister/setStudentLocation',
      checkLabels: 'explorer/checkLabels',
    }),
    toggleProfile() {
      this.showProfile = !this.showProfile;
    },
    removeStudent(student) {
      this.deleteStudent({
        uuid: student.uuid,
      }).then((studentData) => {
        if (studentData) {
          this.changeCurrentStudent(studentData.uuid);
        }
        if (this.systemInfoModal) {
          const infoModal = this.systemInfoModal;
          infoModal.action_completed = false;
          this.setModalFlowInfo({ modalInfo: infoModal, modalUuid: this.systemInfoModal.uuid });
        }
        this.success(this.$t('dashboard.profile.remove_student_success'));
      });
    },
    addStudent() {
      this.stepAux = 1;
      this.inStudentFlow = true;
      this.newStudent = true;
      this.clearCurrentStudent();
      this.showIdentificationModal = true;
    },
    async editStudent(student) {
      this.getStudents({ updateStudent: false });
      this.newStudent = false;
      this.changeCurrentStudent(student.uuid);
      this.inStudentFlow = true;

      if (CONFIG.tenant === 'colombia') { // Processing needed for Colombia-specific modals
      // FIXME: Evaluate splitting this into multiple methods that are called from here
      // Why are we not using the currentStudent instead of the studentToEdit? We're already setting it
        this.studentBrothers = [];

        const hasBrothers = student.siblings?.length > 0;
        this.clearSchoolInstitution();
        if (student.siblings?.length > 0) {
          const lenBrothers = student.siblings.length;
          // eslint-disable-next-line
        for (const index in student.siblings) {
            const sibling = student.siblings[index];
            // let i = index;
            this.studentBrothers.push({
              lenBrothers,
              hasBrothers,
              nationality: sibling.nationality_id !== null ? sibling.nationality_id : null,
              identification_type_id:
              sibling.identification_type_id !== null ? sibling.identification_type_id : null,
              identification_number:
              sibling.identification_number !== null ? sibling.identification_number : null,
              institution_code: sibling.institution_code !== null ? sibling.institution_code : null,
            });
            if (sibling.institution_code !== null) {
              const filter = [
                {
                  fieldname: ELASTIC_SEARCH_FIELDS.INSTITUTION_CODE,
                  fieldvalue: sibling.institution_code,
                },
              ];
              // eslint-disable-next-line
            await this.getSchoolInstitution({ filter }).then(() => {
                this.studentBrothers[index].institution_name = this.getSchoolInstitutionList[index].name;
                this.studentBrothers[index].commune = this.getSchoolInstitutionList[index].commune;
                this.regions.forEach((region) => {
                  region.commune_options.forEach((commune) => {
                    if (commune.name === this.studentBrothers[index].commune) {
                      this.studentBrothers[index].region = region.name;
                      return this.studentBrothers[index].region;
                    }
                    return this.studentBrothers[index].region;
                  });
                });
              });
            }
          }
        } else {
          this.studentBrothers.push({
            hasBrothers,
          });
        }
        if (student.sisben_applicant?.length > 0) {
          this.studentSisben = {
            sisben:
            student.sisben_applicant[0].sisben_category !== null
              ? student.sisben_applicant[0].sisben_category
              : null,
          };
        }
        this.studentIcbf = {
          icbf: student.icbf !== null ? student.icbf : null,
        };
        this.studentConflict = {
          has_conflict:
          student.has_armed_conflict_priority !== null ? student.has_armed_conflict_priority : null,
          conflict_category:
          student.armed_conflict_type?.category_name !== null
          || student.armed_conflict_type?.category_name !== undefined
            ? student.armed_conflict_type?.category_name : null,
        };

        this.studentDisability = {
          has_disability:
          student.has_disability_priority !== null ? student.has_disability_priority : null,
          disability_category:
          student.disability?.disability_name !== null || student.disability?.disability_name !== undefined
            ? student.disability?.disability_name : null,
        };
      }

      this.studentToEdit = student;
      this.showStudentEditModal = true;
    },
    setEditStudent(index) {
      if (index === 0) {
        this.showStudentEditModal = false;
        this.showIdentificationModal = true;
        this.stepAux = 1;
      }
      if (index === 1) {
        this.showStudentEditModal = false;
        this.showStudentGradeModal = true;
      }
      if (index === 2) {
        this.showStudentEditModal = false;
        this.locationStep = 0;
        this.showEditLocation = true;
      }
      if (index === 3) {
        this.showStudentEditModal = false;
        this.showIdentificationModal = true;
        this.stepAux = 7;
      }
      return null;
    },
    clearCurrentStudent() {
      this.studentToEdit = null;
      this.setCurrentStudent({ student: {} });
    },
    changeCurrentStudent(studentUUID) {
      const student = this.students.find((st) => st.uuid === studentUUID);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null }))
        .catch((error) => this.raiseError(error));
    },
    closeProfile() {
      this.showProfile = !this.showProfile;
    },
    backStep(modal) {
      if (modal === 'identification') {
        this.showIdentificationModal = false;
        this.getStudents({ updateStudent: false });
      }
      if (modal === 'location') {
        this.showEditLocation = false;
      }
      if (modal === 'grade') {
        this.showStudentGradeModal = false;
      }
      if (modal === 'additional') {
        this.showIdentificationModal = false;
        this.showStudentEditModal = false;
        this.studentToEdit = null;
      }
      if (this.studentToEdit) {
        this.showStudentEditModal = true;
      }
    },
    setIdentificationStep() {
      if (CONFIG.tenant !== 'newhaven') {
        this.stepAux = 2;
        return this.stepAux;
      }
      if (this.inStudentFlow) {
        this.stepAux = 4;
      } else {
        this.stepAux = 3;
      }
      this.getStudents({ updateStudent: false });
      return this.stepAux;
    },
    nextStep() {
      if (this.stepAux === 3) {
        this.stepAux = 1;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      if (this.stepAux === 8) {
        this.stepAux = 10;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      if (this.stepAux === 6) {
        this.showIdentificationModal = false;
        this.success(this.$t('user_register.identification.success'));
        this.stepAux = 1;
        this.getStudents({ updateStudent: false });
        return this.stepAux;
      }
      if (this.locationStep === 1 && this.showEditLocation) {
        this.locationStep = 2;
        return this.locationStep;
      }
      this.stepAux += 1;
      return this.stepAux;
    },
    setNextStep() {
      this.showIdentificationModal = false;
      if (!this.inStudentFlow) {
        this.locationStep = 0;
        this.showEditLocation = true;
        return null;
      }
      if (!this.studentToEdit) {
        this.showEditLocation = true;
        this.locationStep = 0;
      }
      this.getStudents({ updateStudent: false });
      this.success(this.$t('user_register.identification.success'));
      return null;
    },
    setCommune(commune) {
      this.selectedLocation = {
        coordinates: {
          lat: this.place.lat,
          lng: this.place.lng,
        },
        municipalityName: commune,
        addressDetails: commune.display1,
      };
      this.userLocation = null;
      this.selectedCommune = commune.display1;
      this.locationStep = 1;
    },
    setUserLocation(location) {
      this.selectedCommune = null;
      this.selectedLocation = null;
      this.userLocation = location;
      this.locationStep = 1;
    },
    showLgIdentificationModal() {
      this.stepAux = 1;
      this.newStudent = false;
      this.inStudentFlow = false;
      this.studentToEdit = null;
      this.showIdentificationModal = true;
    },
    setGrades() {
      this.showStudentGradeModal = false;
      this.success(this.$t('user_register.register_grade.success'));
    },
    locationTypeFollowUp() {
      this.showEditLocation = false;
      this.locationStep = -1;
      this.success(this.$t('user_register.identification.success'));
    },
    setLocation() {
      let location = {};
      let addressDetails = '';
      if (!this.pinMoved && this.locationRegister?.final_address?.formatted_address === null) {
        this.finalLat = this.place.lat;
        this.finalLng = this.place.lng;
      } else if (this.locationRegister?.final_address?.lat !== '') {
        this.finalLat = this.locationRegister?.final_address.lat;
        this.finalLng = this.locationRegister?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }
      if (this.locationRegister?.final_address?.formatted_address !== null) {
        addressDetails = this.locationRegister.final_address.formatted_address;
      } else if (this.locationRegister?.address_searched) {
        addressDetails = this.locationRegister.address_searched;
      } else {
        addressDetails = '';
      }
      location = {
        uuid: this.inStudentFlow ? this.currentStudent.uuid : this.legalGuardianUUID,
        address_details: addressDetails,
        address_name: 1,
        address_lat: this.finalLat,
        address_lon: this.finalLng,
        location: this.place.display1,
      };
      if (this.inStudentFlow) {
        if (!this.studentToEdit) {
          this.studentToEdit = this.students[this.students.length - 1];
        }
        this.geocodingHistory();
        this.setStudentLocation({ location, updateStudent: true }).then(() => {
          this.showEditLocation = false;
          if (this.newStudent) {
            this.success(this.$t('user_register.register_location.final_success'));
          }
        });
        return null;
      }
      if (!this.inStudentFlow) {
        location = {
          address_details: addressDetails,
          address_name: 1,
          address_number: addressDetails,
          address_street: addressDetails,
          address_lat: this.finalLat,
          address_lon: this.finalLng,
          location: this.place.display1,
        };
        this.geocodingHistory();
        this.setFastRegisterLocation({ location }).then(() => {
          this.success(this.$t('user_register.register_location.success'));
        });
      }
      this.locationStep = 2;
      return this.showEditLocation;
    },
    geocodingHistory() {
      if (this.locationType === 1) {
        this.locationT = 'use_my_location';
      } else if (this.locationType === 2) {
        this.locationT = 'write_address';
      } else if (this.locationType === 3) {
        this.locationT = 'locate_in_map';
      }

      this.initialLat = this.initialCoordinates.lat;
      this.initialLng = this.initialCoordinates.lng;

      const geoInfo = {
        user: this.legalGuardianUUID || null,
        geotools_code_national: this.locationRegister?.code_national,
        final_lat: this.finalLat,
        final_lng: this.finalLng,
        used_text_first: this.locationType === 2 || this.buttonClicked,
        method: this.locationT,
        geotools_lat: this.initialLat,
        geotools_lng: this.initialLng,
        geotools_geocodification_type: this.locationRegister?.final_address?.geocodification_type !== '' ? this.locationRegister?.final_address?.geocodification_type : null,
        geotools_address: this.locationRegister?.final_address?.formatted_address !== '' ? this.locationRegister?.final_address?.formatted_address : '',
        user_text_input: this.locationRegister?.address_searched ?? null,
      };
      this.sendGeocodingHistory({ geoInfo });
    },
  },
};
</script>
