<template>
  <div
    :style="
      mobile
        ? 'text-align: center; width: 339px; transform: translate(-50%, 0%); margin-left: 50%'
        : ''
    "
  >
    <button type="button" @click="showLocationModal(fromSubmodule)">
      <div style="display: flex" class="sige-button--location">
        <img src="@/assets/dashboard/icons/location_icon.svg" alt="location_icon" />
        <span
          v-if="showedLocation"
          class="sige-button-text"
          style="color: white; padding-top: 0.3rem; margin-left: 1.5rem"
        >
          {{ showedLocation }}
        </span>
      </div>
    </button>

    <!-- modal change location -->
    <v-dialog
      v-if="selectFilter !== -1"
      v-model="showModal"
      :content-class="!mobile ? 'location-map-dialog' : 'location-map-mobile-dialog'"
      style="height: 100vh !important"
      @click:outside="closeModal"
    >
      <LocationSet
        v-if="selectFilter === 0"
        is-in-map
        exact
        @backStep="selectFilter = -1"
        @setCommune="setCommune"
        @setUserLocation="setUserLocation"
      />
      <div
        v-if="selectFilter === 1"
        style="height: 100%"
        class="d-flex flex-column justify-space-between"
      >
        <div style="height: 100%">
          <Actions style="height: 12px; margin-bottom: 12px" @back="selectFilter = 0" />
          <RegisterLogo inline small />
          <SmallTitle
            center-left
            small-top-margin
            style="margin-top: 0px !important"
            :text="'user_register.register_location.title'"
          />
          <TitleInstructions :text="'shared.location.title'" style="margin-bottom: 12px" />
          <SearchLocation
            v-if="selectFilter === 1"
            is-in-map
            :commune="selectedCommune"
            :user-location="userLocation"
            @setUserLocation="setUserLocation"
          />
        </div>
        <div style="padding: 20px 0 0 0">
          <MainButton
            is-active
            small
            :text="'user_register.continue'"
            :loader="setLocationLoader"
            @continue="setLocation()"
          />
        </div>
      </div>
    </v-dialog>
    <!-- end modal change location -->
  </div>
</template>
<script>
import MainButton from '@/components/atoms/buttons/MainButton.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import LocationSet from '@/components/organisms/register/Location.vue';
import SearchLocation from '@/components/shared/SearchLocation.vue';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LocationModal',
  components: {
    LocationSet,
    Actions,
    SmallTitle,
    TitleInstructions,
    RegisterLogo,
    MainButton,
    SearchLocation,
  },
  props: {
    fromSubmodule: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showModal: false,
      selectedLocation: null,
      selectFilter: null,
      userLocation: null,
      selectedCommune: null,
      setLocationLoader: null,
      finalLat: null,
      finalLng: null,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'roles/currentHeadMasterMainSchool',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      municipalities: 'options/municipalities',
      location: 'userRegister/location',
      place: 'userRegister/place',
      pinMoved: 'userRegister/pinMoved',
      initialCoordinates: 'userRegister/initialCoordinates',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showedLocation() {
      if (this.fromSubmodule === 'location') {
        return this.campusDetail.campus_location[0]?.address_street ?? this.$t('dashboard.digital_profile.location.add');
      }
      return this.legalGuardianAddress[0];
    },
  },
  methods: {
    ...mapActions({
      campusLocationPatch: 'institutions/campusLocationPatch',
    }),
    showLocationModal(submodule) {
      const clickText = 'click_dashboard_digital_profile_';
      trackMixPanel(clickText.concat(submodule, '_location'), {
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
      });
      this.showModal = true;
      this.selectFilter = 0;
    },
    closeModal() {
      this.showModal = false;
    },
    setUserLocation(location) {
      this.selectedCommune = null;
      this.userLocation = location;
      this.selectFilter = 1;
    },
    setCommune(commune) {
      this.selectedLocation = {
        coordinates: {
          lat: commune.lat,
          lng: commune.lng,
        },
        municipalityName: commune.display1,
        addressDetails: commune.display1,
      };
      this.userLocation = null;
      this.selectedCommune = commune.display1;
      this.selectFilter = 1;
    },
    setLocation() {
      this.setLocationLoader = true;
      const addressDetails = this.location?.final_address?.formatted_address
        ?? this.location?.address_searched
        ?? '';
      if (!this.pinMoved && this.location?.final_address?.formatted_address === null) {
        this.finalLat = this.place.lat;
        this.finalLng = this.place.lng;
      } else if (this.location.final_address?.lat !== '') {
        this.finalLat = this.location?.final_address.lat;
        this.finalLng = this.location?.final_address.lng;
      } else {
        this.finalLat = this.initialCoordinates.lat;
        this.finalLng = this.initialCoordinates.lng;
      }

      const campusLocation = {
        latitud: this.finalLat,
        longitud: this.finalLng,
        address_street: addressDetails,
        plocation_id: this.place?.p_location_id,
      };
      this.campusLocationPatch({
        campusUuid: this.campusDetail.uuid,
        campusData: campusLocation,
      }).then(() => {
        this.showEditLocation = false;
        this.setLocationLoader = false;
        this.showModal = false;
        this.selectFilter = -1;
      });
    },
  },
};
</script>
